<template>
  <div class="ArtRerult">
    <!-- <navi-gation /> -->
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <el-input v-model.trim="queryObj.reportName" size="small" :placeholder="$t('ReportManagement.DataReport.Placeholder')" @keyup.enter.native="searchList"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="searchList">{{$t('public.Inquire')}}</el-button>
      </el-col>
    </el-row>
    <el-row style="padding: 0px 10px 10px 10px">
      <el-col :span="24">
        <el-button-group>
          <!-- <el-button type="primary" size="small">下载</el-button> -->
          <el-button :disabled="!hasSeleted" :type="typeInfo" size="small" @click="deletes">{{$t('public.Delete')}}</el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-row class="formBox" :gutter="4" style="padding: 0 10px">
      <el-table ref="multipleTable" :data="dictData" stripe size="mini" :row-key="getRowKeys" tooltip-effect="dark" class="sort_table" :default-sort="{ prop: 'createTime', order: 'descending' }" @selection-change="handleSelectionChange" style="width: 100%" @sort-change="sort" :header-cell-style="{ background: '#f7f8fa' }">
        <el-table-column align="center" type="selection" width="60" :reserve-selection="true">
        </el-table-column>
        <el-table-column width="180" header-align="left" align="left" prop="reportName" :label="$t('ReportManagement.DataReport.Name')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="180" header-align="left" align="left" prop="reportType" :label="$t('ReportManagement.DataReport.TypesOf')">
          <template v-slot="{ row }">
            <template v-if="row.reportType == 1"> {{$t('ReportManagement.reportType1')}} </template>
            <template v-if="row.reportType == 2"> {{$t('ReportManagement.reportType2')}} </template>
            <template v-if="row.reportType == 3"> {{$t('ReportManagement.reportType3')}} </template>
            <template v-if="row.reportType == 4"> {{$t('ReportManagement.reportType4')}} </template>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="startTime" :label="$t('public.StartTime')" header-align="left" align="left" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.startTime">{{ row.startTime.slice(0, 10) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="endTime" :label="$t('public.EndTime')" header-align="left" align="left" :show-overflow-tooltip="true">
          <template v-slot="{ row }"><span v-if="row.endTime">{{
              row.endTime.slice(0, 10)
            }}</span></template>
        </el-table-column>
        <el-table-column width="180" prop="createTime" :label="$t('ReportManagement.DataReport.GenerationTime')" header-align="left" align="left" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.createTime">
              {{ row.createTime | dateformat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('public.Controls')" header-align="left" align="left">
          <template v-slot="{ row }">
            <span class="action_icon">
            <img @click="downline(row)" src="@/images/icon_download.png" :title="$t('public.Download')" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
            </span>
            <span class="action_icon">
            <img @click="deletes(row)" src="@/images/icon_del.png" :title="$t('public.Delete')" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="queryObj.currentPage" :limit="queryObj.pageSize" :total="totalCount" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      <ul class="tableCheckBox" style="" v-if="isCheckShow" @mouseleave="leave">
        <li @click="curSelection()">当前页全选</li>
        <li @click="toggleSelection()">取消全选</li>
      </ul>
    </el-row>
    <!-- 提示消息组件 -->
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
import naviGation from '@/components/hearder/index'
import pagination from '@/components/pagination/page.vue'
export default {
  components: { pagination, naviGation, dialogInfo },
  data() {
    return {
      typeInfo: 'info',
      titleInfo: '',
      dictData: [],
      multipleSelection: [],
      queryObj: {
        currentPage: 1,
        pageSize: 10,
        orderColume: 'create_time',
        orderRule: 'DESC',
        reportName: ''
      },
      totalCount: 0,
      isCheckShow: false,// 遮罩全选
      visibaelFlag: false
    }
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length
    }
  },
  created() {
    this.tableList()
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    async tableList() {//请求列表
      const res = await this.$axios.post(
        '/httpServe/report/getDataInfo',
        this.queryObj,
        true
      )
      this.dictData = res.data.content
      this.totalCount = res.data.total
    },
    sort(column) {
      if (column.order === 'ascending') {
        this.queryObj.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.queryObj.orderRule = 'DESC'
      }
      if (column.prop == 'create_time') {
        this.queryObj.orderColume = 'create_time'
      } else if (column.prop == 'reportType') {
        this.queryObj.orderColume = 'report_type'
      }
      this.tableList()
    },
    async deleteBaths(val) {//删除
      if (val.id) {
        let data = []
        data.push(val.id)
        let param = {
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/report/delete',
          param,
          true
        )
        if (res.code == 200) {
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
          this.searchList()
        }
      } else {
        let data = []
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id)
        })
        let param = {
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/report/delete',
          param,
          true
        )
        if (res.code == 200) {
          if (this.multipleSelection.length > 1) {
            this.$message({
              message: this.$t('public.SuccessfullyDeleteds'),
              type: 'success',
              offset: 100
            })
          } else {
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
          }
          this.searchList()
        }
      }
    },
    async downline(val) {// 下载
      var param = {
        path: val.path
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (this.multipleSelection.length > 0) {
        this.typeInfo = 'primary'
      } else {
        this.typeInfo = 'info'
      }
    },
    searchList() {
      this.queryObj.currentPage = 1
      this.tableList()
    },
    handleParentGetList(page, limit) {// 响应分页组件查询事件
      this.queryObj.currentPage = page
      this.queryObj.pageSize = limit
      this.tableList()// 调用查询方法
    },
    maskLayer() {//全选遮罩层
      this.isCheckShow = true
    },
    leave() {// 鼠标移出弹框
      this.isCheckShow = false
    },
    curSelection() {//当前页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    toggleAllSelection() {//所有页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    toggleSelection(rows) {//取消全选
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },
    deletes(val) {//删除
      this.$confirm(this.$t('ReportManagement.CheckDel2'), this.$t('public.PromptMessage'), {
        cancelButtonText: this.$t('public.Cancel'),
        confirmButtonText: this.$t('public.Verify'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.deleteBaths(val)
        })
        .catch(() => {
          return false
        })
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {//确定回调事件
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px;
}
.formBox {
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
::v-deep .el-message-box__btns {
  text-align: left;
}
</style>
